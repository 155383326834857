import { isUserLoggedIn } from '@/auth/utils';

export const canNavigate = (to) => {
  // TODO: Uncomment when ability is implemented
  // ability must be imported from './ability';
  // to.matched.some((route) => ability.can(route.meta.action || 'read', route.meta.resource))

  // this is a simplified version. Use the above when ready.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    return isUserLoggedIn();
  }

  return true;
};

export const _ = undefined;
