import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function getConfiguration(configFiles) {
  return configFiles.keys().reduce((config, key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      return {
        ...config,
        [locale]: configFiles(key),
      };
    }

    return config;
  }, {});
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  return getConfiguration(locales);
}

function loadDateTimeFormats() {
  const dateTimeFormats = require.context('./date-time-formats', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  return getConfiguration(dateTimeFormats);
}

export default new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages: loadLocaleMessages(),
  dateTimeFormats: loadDateTimeFormats(),
});
