import Vue from 'vue';
import axios from 'axios';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';

export default class BackendClient {
  baseApiUrl = '';

  constructor({ baseURL }) {
    this.client = axios.create({ baseURL });

    this.client.interceptors.request.use((config) => {
      const newConfig = { headers: {}, ...config };

      // FIXME: Move to store.
      const token = Vue.$cookies.get(AccessTokenKey);
      if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }

      // FIXME: This is needed to fix a bug in BE, as it expects exactly this content-type.
      // Should be removed once fixed in BE.
      if (config.method.toLowerCase() === 'post') {
        newConfig.headers['Content-Type'] = 'application/json';
      }

      return newConfig;
    });
  }

  getBaseURL() {
    return this.client.defaults.baseURL;
  }

  getUri({ url, params }) {
    return this.client.getUri({ url, params });
  }

  post(uri, data, config) {
    return this.client.post(uri, data, config);
  }

  put(uri, data, config) {
    return this.client.put(uri, data, config);
  }

  patch(uri, data, config) {
    return this.client.patch(uri, data, config);
  }

  get(uri, config) {
    return this.client.get(uri, config);
  }

  delete(uri, config) {
    return this.client.delete(uri, config);
  }
}
