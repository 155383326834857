import Service from '@/config/service-identifiers';
import BackendClient from '@/data/source/remote/BackendClient';
import MockableBackendClient from '@/data/source/mock/MockableBackendClient';

const BackendClientClass = process.env.VUE_APP_API_MOCKABLE ? MockableBackendClient : BackendClient;

const backendClient = new BackendClientClass({ baseURL: process.env.VUE_APP_API_NODE });
const payment = new BackendClientClass({ baseURL: process.env.VUE_APP_API_PAYMENT });
const sockets = new BackendClientClass({ baseURL: process.env.VUE_APP_API_SOCKETS });
const imports = new BackendClientClass({ baseURL: process.env.VUE_APP_API_IMPORTS });
const publicApi = new BackendClientClass({ baseURL: process.env.VUE_APP_API_PUBLIC });

/**
 * To be injected into the store.
 */
export default { 
    [Service.BackendClient]: backendClient, 
    [Service.Payment]: payment, 
    [Service.Sockets]: sockets, 
    [Service.Imports]: imports,
    [Service.Public]: publicApi
};
